import React from 'react';
import '../styles/Contact.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet-async";

function Contact() {

  const form = useRef();

  const serviceID = process.env.REACT_APP_CONTACT_PAGE_EMAIL_FORM_SERVICE_ID;
  const templateID = process.env.REACT_APP_CONTACT_PAGE_EMAIL_FORM_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_CONTACT_PAGE_EMAIL_FORM_PUBLIC_KEY;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceID, templateID, form.current, publicKey)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    form.current.reset();
  };

  return (
    <main className="contact-main">

      <Helmet>
        <title>Contact Us | R &amp; S Constructions</title>
        <meta property="og:title" content="Contact Us | R &amp; S Constructions" />
        <link rel="canonical" href="/contact-us" />
      </Helmet>

      <section className="contact-hero text-center">

        <div className="contact-hero-background-container">
          <fieldset className="contact-hero-fieldset">
            <legend>
              <h1 className="contact-hero-content-title">Contact Us</h1>
            </legend>

            <h1 className="contact-hero-content-text">The Time is Now</h1>

          </fieldset>
        </div>

      </section>

      <section className="contact-info text-center">

        <div className="contact-info-background-container">

          <fieldset className="contact-info-fieldset">
            <legend>
              <h1 className="contact-info-content-title">GET IN TOUCH</h1>
            </legend>

            <p className="contact-info-content-text-description">Whether you have a question, comment, suggestion or concern to share, we look forward to hearing from you.
              <br />Get in touch with us by simply emailing us at RandSConstructionsLLC@hotmail.com or calling us at (301) 768-0298.</p>

            <br /><br /><br /><br /><br /><br />

            <div className="contact-info-content-container">

              <div className="contact-info-content-block">

                <div className="contact-info-content-block-title">
                  <h3>Location</h3> <hr />
                </div>

                <div className="contact-info-content-block-description">
                  <address className="contact-address-info">
                    9235 Broadwater Drive
                    <br />
                    &nbsp;Gaithersburg, MD 20879
                  </address>
                </div>

              </div>

              <div className="contact-info-content-block">

                <div className="contact-info-content-block-title">
                  <h3>Contact</h3> <hr />
                </div>

                <div className="contact-info-content-block-description">
                  <a href='tel:301-768-0298' className='contact-phone-info'>(301) 768-0298</a>
                  <br />
                  <a href='mailto:randsconstructionsllc@hotmail.com' className='contact-email-info'>RANDSCONSTRUCTIONSLLC@HOTMAIL.COM</a>
                </div>

              </div>

            </div>

          </fieldset>

        </div>

      </section>

      <section className="contact-free-estimate">

        <div className="contact-free-estimate-container text-center">

          <h1 className="contact-free-estimate-title">Get a Free Estimate</h1>

          <br />

          <p className="contact-free-estimate-content">We provide free estimates for all our customers.
            Simply fill out the form below and we will contact you as soon as possible.</p>

        </div>

      </section>

      <section className="contact-form text-center">

        <div className="contact-form-container">

          <div className="contact-form-content">

            <div className="contact-form-title">
              <h2>Let's Construct Something Together</h2>
              <hr />
            </div>

            <form ref={form} onSubmit={sendEmail} className="contact-form-details">

              <br />
              <input type="text" name="user_name" placeholder="Name *" required />
              <br /><br />

              <input type="email" name="user_email" placeholder="Email *" required />
              <br /><br />

              <input type="phone" name="user_phone" placeholder="Phone Number" />
              <br /><br />

              <input type="address" name="user_address" placeholder="Street Address" />
              <br /><br />

              <input type="text" name="user_city" placeholder="City" />
              <br /><br />

              <input type="text" name="user_state" placeholder="State *" list="select_state" required />
              <datalist id="select_state">
                <option>DC</option>
                <option>MD</option>
                <option>VA</option>
              </datalist>

              <input type="text" name="user_zipcode" min="0" maxLength={5} placeholder="Zipcode" />
              <br /><br />

              <textarea name="message" placeholder="How Can We Help You? *" required />
              <br /><br />

              <input type="submit" value="Submit" className="contact-form-submit-button" />
              <br /><br />

            </form>

          </div>

        </div>

      </section>

      <section className="contact-google-map">

        <div className="contact-google-map-container">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.1724579302377!2d-77.19387208470651!3d39.17079997952938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7d3044108cb33%3A0x2ca238537bb1d65b!2sR%20%26%20S%20Constructions%20LLC!5e0!3m2!1sen!2sus!4v1657568797369!5m2!1sen!2sus"
            className="contact-google-map-content" title="R &amp; S Constructions LLC Google Map Location" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        </div>

      </section>

    </main>
  );
}

export default Contact;