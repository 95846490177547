import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import '../styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { Divide as Hamburger } from 'hamburger-react';

/* Keep tracks of highlighted current page */
const NavLink = styled(Link)`
    &.active {
        border-bottom-style: solid;
        border-bottom-width: 0.3rem;
        border-bottom-color: yellow;
    }

    @media screen and (max-width: 1200px) {
        &.active {
            background: unset;
            color: #ffff00;
            border-bottom-style: solid;
            border-bottom-width: 0.2rem;
            border-bottom-color: gray;
            border-top-style: unset;
            border-top-width: unset;
            border-top-color: unset;
        }

        &.active:hover {
            background: unset;
            color: #2E95EC;
            border-bottom-style: solid;
            border-bottom-width: 0.2rem;
            border-bottom-color: gray;
            border-top-style: unset;
            border-top-width: unset;
            border-top-color: unset;
        }
    }
`;

function Navbar() {

    const [isOpen, setOpen] = useState(false);

    const handleMobileClick = () => setOpen(!isOpen);

    const closeMobileMenu = () => setOpen(false);

    return (
        <header className='header'>

            <div className='navbar open'>

                <div className='navbar-container'>

                    <Link to='/' className='navbar-logo'>
                        R &amp; S <FontAwesomeIcon icon={faScrewdriverWrench} className='fa-screwdriver-wrench' />
                    </Link>
                    <div className='navbar-menu-icon' onClick={handleMobileClick}>
                        <Hamburger duration={0.5} toggled={isOpen} toggle={setOpen} />
                    </div>

                    <nav className='navbar-sticky'>

                        <ul className={isOpen ? 'navbar-menu active' : 'navbar-menu'}>
                            <li className='navbar-item'>
                                <NavLink to='/' className='navbar-urls' onClick={closeMobileMenu} reloadDocument>Home</NavLink>
                            </li>

                            <li className='navbar-item'>
                                <NavLink to='/about-us' className='navbar-urls' onClick={closeMobileMenu} reloadDocument>About</NavLink>
                            </li>

                            <li className='navbar-item'>
                                <NavLink to='/services' className='navbar-urls' onClick={closeMobileMenu} reloadDocument>Services</NavLink>
                            </li>

                            <li className='navbar-item'>
                                <NavLink to='/gallery' className='navbar-urls' onClick={closeMobileMenu} reloadDocument>Gallery</NavLink>
                            </li>

                            <li className='navbar-item'>
                                <NavLink to='/locations' className='navbar-urls' onClick={closeMobileMenu} reloadDocument>Locations</NavLink>
                            </li>

                            <li className='navbar-item'>
                                <NavLink to='/contact-us' className='navbar-urls' onClick={closeMobileMenu} reloadDocument>Contact</NavLink>
                            </li>
                        </ul>

                    </nav>

                </div>

            </div>

        </header>
    );
}

export default Navbar;