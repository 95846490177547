import React from 'react';
import '../styles/Services.css';
import { Helmet } from "react-helmet-async";

function Services() {
  return (
    <main className="services-main">

      <Helmet>
        <title>Services | R &amp; S Constructions</title>
        <meta property="og:title" content="Services | R &amp; S Constructions" />
        <link rel="canonical" href="/services" />
      </Helmet>

      <section className="services-hero">

        <div className="services-hero-background">

          <div className="services-hero-background-container">

            <h1 className="services-hero-content-title">Services</h1>

          </div>

        </div>

      </section>

      <section className="services-intro">

        <div className="services-intro-container  text-center">

          <h1 className="services-intro-title">Residential Construction General Contractor</h1>

          <br /><br />

          <p className="services-intro-description">
            At R &amp; S Constructions, we are a full service general contracting company, serving in the Maryland area.
            When you hire our experienced team for your next home remodeling project, we will orchestrate everything from start to finish.
            Additionally, we will offer insight, advice and listen to your ideas to help you properly design your remodel or addition in order
            to create an efficient plan that leads to the renovation of your dreams. If you're interested in learning more about our services
            or if you have any questions or concerns about a home improvement project, contact our team today!
          </p>

          <br /><br />

          <a href="/contact-us" target="_self">
            <input type="button" value="Contact Us Today" className="services-intro-contact-button" />
          </a>

        </div>

      </section>

      <section className="services-provided-information">

        <div className="services-provided-information-container">

          <h1 className="services-provided-information-title">Residential Services We Provide</h1>
          <hr className="services-provided-information-title-underline" />

          <h3 className="services-exterior-title">Exterior</h3>

          <div className="services-exterior-content">
            <ul className="services-exterior-content-list">
              <li>Painting</li>
              <li>Finishing</li>
              <li>Deck
                <ul className="services-exterior-content-nested-list">
                  <li>Repair</li>
                  <li>Construction</li>
                  <li>Power Washing</li>
                  <li>Sealing</li>
                  <li>Staining</li>
                  <li>Painting</li>
                  <li>Finishing</li>
                </ul>
              </li>
              <li>Patio
                <ul className="services-exterior-content-nested-list">
                  <li>Repair</li>
                  <li>Construction</li>
                  <li>Power Washing</li>
                  <li>Sealing</li>
                </ul>
              </li>
              <li>Roof
                <ul className="services-exterior-content-nested-list">
                  <li>Repair</li>
                  <li>Installation</li>
                </ul>
              </li>
              <li>Gutter
                <ul className="services-exterior-content-nested-list">
                  <li>Repair</li>
                  <li>Installation</li>
                  <li>Cleaning</li>
                </ul>
              </li>
              <li>Siding
                <ul className="services-exterior-content-nested-list">
                  <li>Vinyl</li>
                  <li>Natural Wood</li>
                  <li>Engineered Wood</li>
                  <li>Cedar</li>
                  <li>Hardie Board</li>
                  <li>Aluminum</li>
                  <li>Natural Stone</li>
                  <li>Faux Stone Veneer</li>
                  <li>Brick</li>
                </ul>
              </li>
              <li>Door
                <ul className="services-exterior-content-nested-list">
                  <li>Repair</li>
                  <li>Installation</li>
                  <li>Painting</li>
                </ul>
              </li>
              <li>Fence
                <ul className="services-exterior-content-nested-list">
                  <li>Repair</li>
                  <li>Installation</li>
                  <li>Painting</li>
                </ul>
              </li>
              <li>Window
                <ul className="services-exterior-content-nested-list">
                  <li>Repair</li>
                  <li>Installation</li>
                </ul>
              </li>
            </ul>
          </div>

          <h3 className="services-interior-title">Interior</h3>

          <div className="services-interior-content">
            <ul className="services-interior-content-list">
              <li>Painting</li>
              <li>Finishing</li>
              <li>Structural Repairs</li>
              <li>Kitchen
                <ul className="services-interior-content-nested-list">
                  <li>Remodeling</li>
                  <li>Repair</li>
                  <li>Installation</li>
                </ul>
              </li>
              <li>Bathroom
                <ul className="services-interior-content-nested-list">
                  <li>Remodeling</li>
                  <li>Repair</li>
                  <li>Installation</li>
                </ul>
              </li>
              <li>Basement Finishing</li>
              <li>Flooring
                <ul className="services-interior-content-nested-list">
                  <li>Hardwood</li>
                  <li>Laminate</li>
                  <li>Vinyl</li>
                  <li>Carpet</li>
                  <li>Real Stone</li>
                  <li>Tile</li>
                  <li>Composite</li>
                </ul>
              </li>
              <li>Electrical</li>
              <li>Plumbing</li>
              <li>Drywall</li>
              <li>Door
                <ul className="services-interior-content-nested-list">
                  <li>Repair</li>
                  <li>Installation</li>
                </ul>
              </li>
              <li>Fan
                <ul className="services-interior-content-nested-list">
                  <li>Repair</li>
                  <li>Installation</li>
                </ul>
              </li>
              <li>Furniture Assembly</li>
              <li>Plumbing Fixture Installation</li>
            </ul>
          </div>

        </div>

      </section>

    </main>
  );
}

export default Services; 