import React from 'react';
import "../styles/Home.css";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <main className="home-main">

      <Helmet>
        <title>R &amp; S Constructions | General Contractor &amp; Home Remodeling</title>
        <meta property="og:title" content="R &amp; S Constructions | General Contractor &amp; Home Remodeling" />
        <link rel="canonical" href="/" />
      </Helmet>

      <section className="home-hero">

        <div className="home-hero-background-container">

          <div className="home-hero-content-container">

            <h1 className="home-hero-content-title-one">R &amp; S Constructions LLC</h1>

            <br />

            <h2 className="home-hero-content-title-two">Serving Maryland &amp; DC Since 2016</h2>

            <br /><br />

            <div className="home-hero-button-container">

              <a href="/services" target="_self">
                <input type="button" value="Our Services" className="home-hero-contact-button-one" />
              </a>

              <a href="/contact-us" target="_self">
                <input type="button" value="Free Estimate" className="home-hero-contact-button-two" />
              </a>

            </div>

          </div>

        </div>

      </section>

      <section className="home-intro">

        <div className="home-intro-container text-center">

          <h1 className="home-intro-title">Full-Service General Contractor</h1>

          <br /><br />

          <p className="home-intro-description">
            R &amp; S Constructions is a full-service residential design and build remodeling firm in Maryland. Specializing in 
            new construction, custom home building and residential renovations. Dream big, dream small or dream one project 
            at a time. R &amp; S Constructions has a diverse team of qualified professionals that can bring your vision to life.
            We have been in business for over 6 years and strive toward sustainable, eco-friendly
            construction using best practices. Our home remodeling contractors offer complete home remodeling, additions and
            alterations, kitchen remodeling, bathroom remodeling, basement finishing and much more for homes in Maryland. R &amp; S Constructions
            is a fully licensed and insured Certified General Contractor in the State of Maryland <strong>(MHIC #05-136714)</strong>.

            <br /><br />

            R &amp; S Constructions takes pride in quality craftsmanship, attention to detail, and provides full and open
            communication with each and every client. With each project, it is understood that this is not simply about
            remodeling bathrooms, kitchens, or basements - it is about establishing a vision with the client and applying that
            vision as the central focus throughout the construction process. We take great satisfaction in delivering a quality 
            project on schedule to every client. Our motivation is to provide you with the highest quality workmanship and complete customer satisfaction!
          </p>

          <br /><br />

          <a href="/contact-us" target="_self">
            <input type="button" value="Contact Us Today" className="home-intro-contact-button" />
          </a>

        </div>

      </section>

      <section className="home-services-intro">

        <div className="home-services-intro-container">

          <h1 className="home-services-intro-title">We Offer a Wide Range of Services</h1>

          <br /><br />

          <p className="home-services-intro-description">
            From conception to completion, our entire team of estimators, designers, project managers and experienced
            executives make our clients' needs a priority. We have experience in a wide variety of projects and delivery
            methods, and use both time-proven practices and cutting-edge techniques to make sure our customers' projects meet
            their maximum potential.

            <br /><br />

            <a href="/services" className="home-services-intro-description-link">Learn More</a>

          </p>

        </div>

      </section>

    </main>
  );
}

export default Home;