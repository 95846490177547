import React from 'react';
import '../styles/Footer.css';
import Logo from '../assets/RandSConstructionsLLCLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className='footer'>

      <div className='footer-primary'>

        <div className='container'>

          <div className='footer-primary-inner'>

            <div className='footer-left'>

              <div className='footer-left-wrapper'>
                <div className='footer-logo'>
                  <a href='/'>
                    <img src={Logo} alt='R &amp; S Constructions LLC' className='footer-logo-image' />
                  </a>
                </div>

                <div className='footer-location'>
                  <ul>
                    <li>
                      <h3 className='footer-location-title'>LOCATION</h3>
                    </li>
                    <li>
                      <address className="footer-address-info">
                        9235 BROADWATER DRIVE <br /> GAITHERSBURG, MD 20879
                      </address>
                    </li>
                  </ul>
                </div>

                <div className='footer-contact-info'>
                  <ul>
                    <li>
                      <h3 className='footer-contact-info-title'>CONTACT</h3>
                    </li>
                    <li>
                      <a href='mailto:randsconstructionsllc@hotmail.com' className='footer-email-info'>RANDSCONSTRUCTIONSLLC@HOTMAIL.COM</a>
                    </li>
                    <li>
                      <a href='tel:301-768-0298' className='footer-phone-info'>(301) 768-0298</a>
                    </li>
                  </ul>
                </div>

              </div>
            </div>

            <div className='footer-right'>

              <div className='footer-right-wrapper'>
                <ul className='footer-list'>
                  <li className='footer-item'>
                    <a href='/' className='footer-link'>home</a>
                  </li>
                  <li className='footer-item'>
                    <a href='/about-us' className='footer-link'>about</a>
                  </li>
                  <li className='footer-item'>
                    <a href='/services' className='footer-link'>services</a>
                  </li>
                  <li className='footer-item'>
                    <a href='/gallery' className='footer-link'>gallery</a>
                  </li>
                  <li className='footer-item'>
                    <a href='/locations' className='footer-link'>locations</a>
                  </li>
                  <li className='footer-item'>
                    <a href='/contact-us' className='footer-link'>contact</a>
                  </li>
                </ul>
              </div>
            </div>

          </div>

        </div>

      </div>

      <div className='footer-secondary'>

        <div className='container'>

          <div className='footer-secondary-inner'>

            <div className='footer-copyright-info'>

              <span className='footer-copyright-info-title'>
                R &amp; S CONSTRUCTIONS LLC &copy; {new Date().getFullYear()} | ALL RIGHTS RESERVED
              </span>
            </div>

            <div className='footer-social-apps-info'>
              <ul className='footer-social-apps-info-list'>
                <li className='footer-social-apps-info-item'>
                  <a href='https://www.facebook.com/RandSCONSTRUCTIONSLLC' target='_blank' rel="noreferrer" alt='Facebook Icon'
                    className='footer-social-apps-info-link'>
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className='footer-social-apps-info-item'>
                  <a href='https://www.linkedin.com/company/r-and-s-constructions/' target='_blank' rel="noreferrer" alt='LinkedIn Icon'
                    className='footer-social-apps-info-link'>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
              </ul>
            </div>

          </div>

        </div>

      </div>

    </div>
  );
}

export default Footer;