/* Represents Root Component for Application */

import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

//List of Route Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Locations from './pages/Locations';
import Contact from './pages/Contact';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/about-us' element={< About />}></Route>
          <Route path='/services' element={< Services />}></Route>
          <Route path='/gallery' element={< Gallery />}></Route>
          <Route path='/locations' element={< Locations />}></Route>
          <Route path='/contact-us' element={< Contact />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
