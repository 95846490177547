import React from 'react';
import '../styles/Locations.css';
import { Helmet } from "react-helmet-async";

function Locations() {

  return (
    <main className="locations-main">

      <Helmet>
        <title>Locations | R &amp; S Constructions</title>
        <meta property="og:title" content="Locations | R &amp; S Constructions" />
        <link rel="canonical" href="/locations" />
      </Helmet>

      <section className="locations-hero">

        <div className="locations-hero-background-container">

          <div className="locations-hero-content-container">

            <h1 className="locations-hero-content-title">Locations</h1>

          </div>

        </div>

      </section>

      <section className="locations-intro">

        <div className="locations-intro-container">

          <h1 className="locations-intro-title">Professional Contractors in the Maryland Area</h1>

          <br />

          <p className="locations-intro-description">
            R &amp; S Constructions is proud to serve the residents in Maryland with top quality residential construction 
            and renovation services. Since 2016, we've been providing a variety of top general contracting and remodeling work for homes throughout the area. We offer various services to fit your remodeling needs. 
            If you want the best results for your home renovation project, then R &amp; S Constructions is the perfect partner for you.
            <br /><br />

            In addition to a wide range of services, we also operate in a wide range of locations throughout Maryland 
            and the surrounding areas. If you do not 
            see your location listed in the cities provided below, please call us at <strong><a href='tel:301-768-0298' className='locations-phone-info'>(301) 768-0298</a></strong>. 
            We have several helpful representatives who will be more than happy to provide you with the information you need over the phone. You can even 
            take this time to schedule your in-home estimate. Contact us for any questions, comments, suggestions or concerns on how we can help.</p>

          <br /><br />

          <a href="/contact-us" target="_self">
            <input type="button" value="Contact Us Today" className="locations-intro-contact-button" />
          </a>

        </div>

      </section>


      <section className="locations-service-areas">

        <div className="locations-service-areas-container" id="locations-service-areas-reverse">

          <h1 className="locations-service-areas-title">Maryland Locations Served</h1>

          <hr className="locations-service-areas-title-underline" />

          <br />

          <div className="locations-service-areas-list-container" id="locations-service-areas-list-container-column">

            <ul className="locations-service-areas-list">
              <li>Adelphi</li>
              <li>Annapolis</li>
              <li>Arbutus</li>
              <li>Arnold</li>
              <li>Aspen Hill</li>
              <li>Ballenger Creek</li>
              <li>Baltimore</li>
              <li>Beltsville</li>
              <li>Bethesda</li>
              <li>Bowie</li>
              <li>Brooklyn Park</li>
              <li>Calverton</li>
              <li>Carney</li>
              <li>Catonsville</li>
              <li>Cheverly</li>
              <li>Chillum</li>
              <li>Clarksburg</li>
              <li>Clinton</li>
              <li>Cloverly</li>
              <li>Colesville</li>
              <li>College Park</li>
              <li>Columbia</li>
              <li>Crofton</li>
              <li>Damascus</li>
              <li>Dundalk</li>
              <li>East Riverdale</li>
              <li>Eldersburg</li>
            </ul>

            <br />

            <ul className="locations-service-areas-list">
              <li>Ellicott City</li>
              <li>Elkridge</li>
              <li>Essex</li>
              <li>Fairland</li>
              <li>Ferndale</li>
              <li>Flower Hill</li>
              <li>Frederick</li>
              <li>Gaithersburg</li>
              <li>Germantown</li>
              <li>Glen Burnie</li>
              <li>Glenmont</li>
              <li>Glenn Dale</li>
              <li>Greenbelt</li>
              <li>Hagerstown</li>
              <li>Hyattsville</li>
              <li>Kemp Mill</li>
              <li>Lake Shore</li>
              <li>Langley Park</li>
              <li>Laurel</li>
              <li>Lochearn</li>
              <li>Maryland City</li>
              <li>Middle River</li>
              <li>Milford Mill</li>
              <li>Montgomery Village</li>
              <li>North Bethesda</li>
              <li>North Laurel</li>
              <li>Odenton</li>
            </ul>

            <br />

            <ul className="locations-service-areas-list">
              <li>Olney</li>
              <li>Owings Mills</li>
              <li>Parkville</li>
              <li>Parole</li>
              <li>Pasadena</li>
              <li>Pikesville</li>
              <li>Potomac</li>
              <li>Randallstown</li>
              <li>Redland</li>
              <li>Reisterstown</li>
              <li>Rockville</li>
              <li>Rosedale</li>
              <li>Rossville</li>
              <li>Seabrook</li>
              <li>Silver Spring</li>
              <li>Severna Park</li>
              <li>Severn</li>
              <li>South Laurel</li>
              <li>Summerfield</li>
              <li>Takoma Park</li>
              <li>Towson</li>
              <li>Urbana</li>
              <li>Waldorf</li>
              <li>Westerminster</li>
              <li>Wheaton</li>
              <li>White Oak</li>
              <li>Woodlawn</li>
            </ul>

          </div>

        </div>

      </section>

      <section className="locations-google-map">

        <div className="locations-google-map-container" id="map">

          <iframe src="https://www.google.com/maps/d/embed?mid=1wWnmtZhNBi1ZvGpchxnAgxsVGJKDcOc&hl=en&ehbc=2E312F"
            width="640" height="480" className="locations-google-map-content" title="Maryland Google Map Locations Served" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        </div>

      </section>

    </main>
  );
}

export default Locations;