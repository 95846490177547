import React from 'react';
import "../styles/About.css";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet-async";

function About() {

  const form = useRef();

  const serviceID = process.env.REACT_APP_ABOUT_PAGE_EMAIL_FORM_SERVICE_ID;
  const templateID = process.env.REACT_APP_ABOUT_PAGE_EMAIL_FORM_TEMPLATE_ID
  const publicKey = process.env.REACT_APP_ABOUT_PAGE_EMAIL_FORM_PUBLIC_KEY;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(serviceID, templateID, form.current, publicKey)
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    form.current.reset();
  };

  return (
    <main className="about-main">

      <Helmet>
        <title>About Us | R &amp; S Constructions</title>
        <meta property="og:title" content="About Us | R &amp; S Constructions" data-react-helmet="true" />
        <meta name="description"
          content="This is the about description for R &amp; S Constructions." data-react-helmet="true" />
        <link rel="canonical" href="/about-us" data-react-helmet="true" />

      </Helmet>

      <section className="about-hero">

        <div className="about-hero-background-container">

          <div className="about-hero-content-container">

            <h1 className="about-hero-content-title">About Us</h1>

          </div>

        </div>

      </section>

      <section className="about-intro">

        <div className="about-intro-primary-container">

          <div className="about-intro-row">

            <div className="about-intro-left-wrapper">

              <h1 className="about-intro-title">About R &amp; S Constructions</h1>

              <br />

              <p className="about-intro-description">
                R &amp; S Constructions is a full-service general contractor licensed in the state of Maryland since 2016 that
                builds quality residential homes that add value to the communities we serve. We bring a passion for innovation
                and a commitment to quality to every job we do. Our company has an unwavering dedication to personal and
                professional integrity and has become a premier general contractor by delivering outstanding quality and value
                to all our clients.

                <br /><br />
                R &amp; S Constructions is carrying the values, ideals, traditions, and ethics of years of experience in all aspects
                of residential construction. Our unwavering loyalty and dedication to our employees, our clients, and our craft
                has been integral in cultivating our statewide client base. We appreciate the dedication and loyalty shown to us
                by our employees and clients and look towards the future to perfect our brand.

                <br /><br />
                Our primary goal is exceeding our client's expectations in all aspects of the construction process. Our values of
                integrity, honesty, and accountability extend to all aspects of the project from estimating through closeout. We
                believe open communication between the project team and client is a key component to the success of each project.
                Our culture is engrained in all our employees and suppliers in order to strive for 100% client satisfaction.

                <br /><br />
                We welcome and encourage client participation at every step in the construction process. Our experience,
                our belief in open communication, and our high-quality standards enable us to guide our clients through every
                stage of the construction process. Our customers know that our ingenuity, technology and innovative results will
                make us a leader in this dynamic market. It is comforting to know that our quality and commitment are unwavering,
                while aspiring to become a market leader in the industry. We plunge into the future with great passion, anticipation
                and enthusiasm for our ever-growing business.

                <br /><br />
                <a href='/contact-us' className='about-contact-redirect'><strong>Contact us today</strong></a> or call us at <a href='tel:301-768-0298' className='about-phone-info'><strong>(301) 768-0298</strong></a>.
              </p>

            </div>

            <br /><br />

            <div className="about-intro-right-wrapper">

              <div className="about-contact-form-container">

                <h2 className="about-contact-form-title">Request A Free Estimate</h2>

                <br /><br />

                <form ref={form} onSubmit={sendEmail} className="about-contact-form-details">

                  <input type="text" name="about_user_name" placeholder="Name *" required />
                  <br /><br /><br />

                  <input type="email" name="about_user_email" placeholder="Email *" required />
                  <br /><br /><br />

                  <input type="phone" name="about_user_phone" placeholder="Phone Number" />
                  <br /><br /><br />

                  <input type="address" name="about_user_address" placeholder="Street Address" />
                  <br /><br /><br />

                  <input type="text" name="about_user_city" placeholder="City" />
                  <br /><br /><br />

                  <input type="text" name="about_user_state" placeholder="State *" list="select_state" required />
                  <datalist id="select_state">
                    <option>DC</option>
                    <option>MD</option>
                    <option>VA</option>
                  </datalist>
                  <br /><br /><br />

                  <input type="text" name="about_user_zipcode" min="0" maxLength={5} placeholder="Zipcode" />
                  <br /><br /><br />

                  <textarea name="about_message" placeholder="How Can We Help You? *" required />
                  <br /><br /><br />

                  <input type="submit" value="Submit" className="about-form-submit-button" />

                </form>

              </div>

            </div>

          </div>

        </div>

      </section>

    </main>
  );
}

export default About;