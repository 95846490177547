import React from 'react';
import '../styles/Gallery.css';
import { Helmet } from "react-helmet-async";

function Gallery() {
  return (

    <main className="gallery-main">

      <Helmet>
        <title>Gallery | R &amp; S Constructions</title>
        <meta property="og:title" content="Gallery | R &amp; S Constructions" />
        <link rel="canonical" href="/locations" />
      </Helmet>

      <section className="gallery-coming-soon text-center">
        <h1 className="gallery-coming-soon-content-title">Under Construction <br /><br /> Coming Soon</h1>

      </section>

    </main>
  );
}

export default Gallery; 